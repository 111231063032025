import jsXLS from "js-export-excel";
import Rom from "../service/Num_Romano";

class XLSjs {

    Seccion(seccion,data) {
        var option = {};
        option.fileName = "Lista de Sección "+seccion.abrev+seccion.sec;
        var body = [];
    
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].estudiante,
                C3: data[i].apellido+' '+data[i].nombre,
                C4: data[i].plan,
                C5: data[i].turno,
                C6: data[i].correo,
                C7: data[i].tlf_celular
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7"],
            sheetHeader: ['Nº','Nº Cédula', 'Apellidos y Nombres', 'Plan de Estudio', 'Turno', 'Correo', 'Celular'],
            columnWidths: [2, 5, 15, 15, 5, 15, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }


    Preinscritos(extension,periodo,data) {
        var option = {};
        option.fileName = "Lista de Preinscritos "+periodo.nombre+". "+extension.nombre;
        var body = [];
    
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].fecha_reg,
                C3: data[i].cedula,
                C4: data[i].apellido+' '+data[i].nombre,
                C5: data[i].plan,
                C6: data[i].turno,
                C7: data[i].correo,
                C8: data[i].tlf_celular
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8"],
            sheetHeader: ['Nº','Fecha y Hora','Nº Cédula', 'Apellidos y Nombres', 'Plan de Estudio', 'Turno', 'Correo', 'Celular'],
            columnWidths: [2, 5, 5, 15, 15, 5, 15, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Inscritos(nucleo,periodo,data) {
        var option = {};
        option.fileName = nucleo+' '+periodo+' Inscritos';
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].plan_estudio+' '+data[i].codplan,
                C3: data[i].nasignatura,
                C4: data[i].cod,
                C5: data[i].cod+'/'+periodo,
                C6: data[i].doc,
                C7: 'S'+data[i].sem+data[i].secc,
                C8: data[i].estudiante,
                C9: data[i].apellido,
                C10: data[i].nombre,
                C11: data[i].correo,
                C12: data[i].tlf_celular,
                C13: data[i].fecha_reg
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13"],
            sheetHeader: ['Nº', 'Plan de Estudio', 'Asignatura', 'Código', 'Código/Período', 'Docente', 'Sección','Nº Cédula', 'Apellidos', 'Nombres', 'Correo', 'Celular','Fecha'],
            columnWidths: [2, 15, 15, 5, 10, 15, 5, 5, 15, 15, 15, 7, 10],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    InscritosDoc(data) {
        var option = {};
        option.fileName = data.nucleo+' Inscritos';
        var body = [];
        for (var i = 0; i < data.inscripcion.length; i++) {
			body.push({
                C1: i+1,
                C2: data.inscripcion[i].plan_estudio+' '+data.inscripcion[i].codplan,
                C3: data.asignatura,
                C4: data.cod,
                C5: data.cod+'/'+data.periodo,
                C6: data.doc,
                C7: data.secf,
                C8: data.inscripcion[i].estudiante,
                C9: data.inscripcion[i].apellido,
                C10: data.inscripcion[i].nombre,
                C11: data.inscripcion[i].correo,
                C12: data.inscripcion[i].tlf_celular,
                C13: data.inscripcion[i].fecha_reg
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13"],
            sheetHeader: ['Nº', 'Plan de Estudio', 'Asignatura', 'Código', 'Código/Período', 'Docente', 'Sección','Nº Cédula', 'Apellidos', 'Nombres', 'Correo', 'Celular','Fecha'],
            columnWidths: [2, 15, 15, 5, 10, 15, 5, 5, 15, 15, 15, 7, 10],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    ActaCalificaciones(data) {
        var option = {};
        option.fileName = data.nucleo+' Calificaciones';
        var body = [];
    
        for (var i = 0; i < data.inscripcion.length; i++) {
			body.push({
                C1: i+1,
                C2: data.inscripcion[i].plan_estudio+' '+data.inscripcion[i].codplan,
                C3: data.asignatura,
                C4: data.cod,
                C5: data.ndocente,
                C6: data.secf,
                C7: data.inscripcion[i].estudiante,
                C8: data.inscripcion[i].apellido+' '+data.inscripcion[i].nombre,
                C9: data.inscripcion[i].correo,
                C10: data.inscripcion[i].tlf_celular
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10"],
            sheetHeader: ['Nº', 'Plan de Estudio', 'Asignatura', 'Código', 'Docente', 'Sección','Nº Cédula', 'Apellidos y Nombres', 'Correo', 'Celular'],
            columnWidths: [2, 15, 15, 5, 15, 5, 5, 15, 15, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }


    PagosInscrip(nucleo,periodo,data) {
        var option = {};
        option.fileName = nucleo+' '+periodo +' Pagos Inscripciones';
        var body = [];
        var formapago = '';
        var telefonos = '';
        var nuevo = '';
        var beca = '';
        for (var i = 0; i < data.length; i++) {
            if(data[i].formapago==1){formapago = 'Al Contado';}
            if(data[i].formapago==2){formapago = 'A Credito';}
            if(data[i].formapago==3){formapago = 'Al Contado Una Materia';}
            if(data[i].formapago==0){formapago = 'No Definido';}
            if(data[i].datos.tlf_celular && data[i].datos.tlf_local){
                telefonos = data[i].datos.tlf_celular+' / '+data[i].datos.tlf_local;
            } else if(data[i].datos.tlf_celular){
                telefonos = data[i].datos.tlf_celular;
            } else if(data[i].datos.tlf_local){
                telefonos = data[i].datos.tlf_local;
            } else {telefonos = '';}
            if(periodo == data[i].mat.periodo){
                nuevo = 'Nuevo';
            } else {nuevo = 'Regular';}
            if(data[i].mat.beca){
                beca = 'SI';
            } else {beca = '';}
			body.push({
                C1: i+1,
                C2: data[i].fechareg,
                C3: data[i].datos.nucleo,
                C4: data[i].estudiante,
                C5: data[i].apellido,
                C6: data[i].nombre,
                C7: data[i].datos.sexo,
                C8: data[i].plan_estudio,
                C9: data[i].semestre,
                C10: nuevo,
                C11: data[i].mat.periodo,
                C12: data[i].mat.turno,
                C13: formapago,
                C14: data[i].datos.correo,
                C15: telefonos,
                C16:  this.convfech(data[i].datos.fecha_nac),
                C17: data[i].datos.lugarnac,
                C18: data[i].asignaturas,
                C19: beca,
                C20: data[i].factura,
                C21: data[i].monto,
                C22: data[i].matricula,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17","C18","C19","C20","C21","C22"],
            sheetHeader: ['Nº','Fecha','Extensión', 'N° de Cédula', 'Apellidos', 'Nombres', 'Sexo', 'Carrera', 'Semestre',
                'Estatus', 'Período de Ingreso', 'Turno', 'Modalidad de Pago', 'Correo', 'N° Telefónico',
                'Fecha Nac.', 'Lugar de Nac.' , 'Nº Asignaturas', 'Becario', 'Nº Control', 'Monto', 'IDMAT'],
            columnWidths: [2, 10, 10, 5, 10, 10, 5, 10, 5, 5, 10, 5, 5, 10, 10, 10, 5, 5, 5, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    PagosInscriptos(nucleo,periodo,data) {
        var option = {};
        option.fileName = nucleo+' '+periodo +' Pagos Inscripciones';
        var body = [];
        var formapago = '';
        for (var i = 0; i < data.length; i++) {
            if(data[i].formapago==1){formapago = 'Al Contado';}
            if(data[i].formapago==2){formapago = 'A Credito';}
            if(data[i].formapago==3){formapago = 'Al Contado Una Materia';}
            if(data[i].formapago==0){formapago = 'No Definido';}
			body.push({
                C1: i+1,
                C2: data[i].fechareg,
                C3: data[i].datos.nucleo,
                C4: data[i].estudiante,
                C5: data[i].apellido,
                C6: data[i].nombre,
                C7: data[i].datos.sexo,
                C8: data[i].plan_estudio,
                C9: data[i].mat.turno,
                C10: data[i].asignaturas,
                C11: data[i].semestre,
                C12: formapago,
                C13: data[i].factura,
                C14: data[i].monto,
                C15: data[i].datos.correo,
                C16: data[i].datos.tlf_celular,
                C17: data[i].datos.edad,
                C18: data[i].datos.discapacidad,
                C19: data[i].datos.etnia,
                C20: data[i].indice,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17","C18","C19","C20"],
            sheetHeader: ['Nº','Fecha', 'Extensión', 'N° de Cédula', 'Apellidos', 'Nombres', 'Sexo', 'Carrera',
                'Turno', 'N° Asignaturas', 'Semestre', 'Modalidad de Pago', 'Nº Control', 'Monto','Correo','Celular','Edad',
                'Discpacidad','Etnia','IRA'],
            columnWidths: [2, 10, 10, 5, 10, 10, 5, 10, 10, 10, 5, 10, 5, 5, 10, 10,5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    RepAcadEstud(nucleo,periodo,data) {
        var option = {};
        option.fileName = 'Reporte Académico de Estudiantes '+nucleo+' '+periodo;
        var body = [];
        let cedula = null;
        let contador = 1;
        for (var i = 0; i < data.length; i++) {
            if(cedula==null || cedula!=data[i].estudiante){
                body.push({
                    C1: contador,
                    C2: nucleo,
                    C3: data[i].plan_estudio,
                    C4: periodo,
                    C5: data[i].estudiante,
                    C6: data[i].apellido+', '+data[i].nombre,
                    C7: data[i].cod,
                    C8: data[i].nasignatura,
                    C9: data[i].doc,
                    C10: 'S'+data[i].sem+data[i].secc,
                    C11: data[i].tlf_celular,
                    C12: data[i].correo,
                });
                contador++;
            } else {
                body.push({
                    C1: null,
                    C2: null,
                    C3: null,
                    C4: null,
                    C5: null,
                    C6: null,
                    C7: data[i].cod,
                    C8: data[i].nasignatura,
                    C9: data[i].doc,
                    C10: 'S'+data[i].sem+data[i].secc,
                    C11: null,
                    C12: null,
                });
            }
            cedula = data[i].estudiante;
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12"],
            sheetHeader: ['Nº', 'Extensión','Plan de Estudio','Período', 'N° de Cédula', 'Apellidos y Nombres', 'Código', 'Asignatura',
                'Profesor', 'Sección', 'Celular', 'Correo'],
            columnWidths: [5, 10, 15, 5, 5, 15, 5, 10, 15, 5, 10, 15],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    OfertaDOC(perido,data) {
        var option = {};
        option.fileName = 'Docentes Extension '+perido.nombre;
        var body = [];
        var contador = 0;
        var num = 0;
        var ced = null;
        var nuc = null;
        var nombre = null;
        var total = null;
        var horas = null;
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].oferta.length; j++) {
                if((i+1) != contador){
                    contador++; 
                    num = contador;
                    ced = data[i].oferta[j].docente;
                    nombre = data[i].oferta[j].doc;
                    nuc = data[i].oferta[j].nucleo;
                    total = data[i].total;
                } else {
                    num = null;
                    ced = null;
                    nombre = null;
                    nuc = null;
                    total = null;
                }
                if(data[i].oferta[j].fusion){
                    horas = null;
                } else {
                    horas = data[i].oferta[j].horasaula;
                }
                body.push({
                    C1: num,
                    C2: nuc,
                    C3: ced,
                    C4: nombre,
                    C5: data[i].oferta[j].nucleo,
                    C6: data[i].oferta[j].codplan+' '+data[i].oferta[j].plan,
                    C7: data[i].oferta[j].cod,
                    C8: data[i].oferta[j].asignatura,
                    C9: 'S'+data[i].oferta[j].sem+data[i].oferta[j].secc,
                    C10: data[i].oferta[j].fusioncod,
                    C11: horas,
                    C12: total,
                    C13: data[i].oferta[j].inscritos,
                });
            }
        }
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13"],
            sheetHeader: ['Nº', 'Extensión del Docente', 'Nº Cédula', 'Apellido y Nombre', 'Extensión', 'Carrera', 'Código', 'Asignatura','Sección', 
                'Fusión', 'Horas Semanales', 'Total Horas Semanales', 'Nº Inscritos'],
            columnWidths: [2, 20, 5, 20, 20, 20, 5, 20, 5, 5, 7, 10, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    PagosCuotas(periodo,data) {
        var option = {};
        option.fileName = 'Reporte de Cuotas Inscritos a Crédito período '+periodo;
        var body = [];
        var cuota1;
        var cuota2;
        var cuota3;
        var cuota4;
        var deuda1;
        var deuda2;
        var deuda3;
        var deuda4;
        var contador1 = 0;
        var contador2 = 0;
        var contador3 = 0;
        var contador4 = 0;
        for (var i = 0; i < data.length; i++) {
            if(data[i].cuota1.length){
                if(data[i].cuota1[0].pagada){cuota1 = 'Pagada'; contador1++;} else {cuota1 = 'Deuda';}
                if(!data[i].cuota1[0].pagada && data[i].cuota1[0].factura>0 && data[i].cuota1[0].monto){
                    deuda1= parseFloat(data[i].cuota1[0].montofinal-data[i].cuota1[0].montopagados)/parseFloat(data[i].cuota1[0].num_cuotas)+parseFloat(data[i].cuota1[0].ajustebs/data[i].tasa1)/parseFloat(data[i].cuota1[0].num_cuotas);
                } else if(!data[i].cuota1[0].pagada){
                    deuda1= parseFloat(data[i].cuota1[0].monto);
                } else {deuda1 = 0;}
            } else { cuota1 = null; }
            if(data[i].cuota2.length){
                if(data[i].cuota2[0].pagada){cuota2 = 'Pagada'; contador2++;} else {cuota2 = 'Deuda';}
                if(!data[i].cuota2[0].pagada && data[i].cuota2[0].factura>0){
                    deuda2= parseFloat(data[i].cuota2[0].montofinal-data[i].cuota2[0].montopagados)/parseFloat(data[i].cuota2[0].num_cuotas)+parseFloat(data[i].cuota2[0].ajustebs/data[i].tasa2)/parseFloat(data[i].cuota2[0].num_cuotas);
                } else if(!data[i].cuota2[0].pagada){
                    deuda2= parseFloat(data[i].cuota2[0].monto);
                } else {deuda2 = 0;}
            } else { cuota2 = null; }
            if(data[i].cuota3.length){
                if(data[i].cuota3[0].pagada){cuota3 = 'Pagada'; contador3++;} else {cuota3 = 'Deuda';}
                if(!data[i].cuota3[0].pagada && data[i].cuota3[0].factura>0){
                    deuda3= parseFloat(data[i].cuota3[0].montofinal-data[i].cuota3[0].montopagados)/parseFloat(data[i].cuota3[0].num_cuotas)+parseFloat(data[i].cuota3[0].ajustebs/data[i].tasa3)/parseFloat(data[i].cuota3[0].num_cuotas);
                } else if(!data[i].cuota3[0].pagada){
                    deuda3= parseFloat(data[i].cuota3[0].monto);
                } else {deuda3 = 0;}
            } else { cuota3 = null; }
            if(data[i].cuota4.length){
                if(data[i].cuota4[0].pagada){cuota4 = 'Pagada'; contador4++;} else {cuota4 = 'Deuda';}
                if(!data[i].cuota4[0].pagada && data[i].cuota4[0].factura>0){
                    deuda4= parseFloat(data[i].cuota4[0].montofinal-data[i].cuota4[0].montopagados)/parseFloat(data[i].cuota4[0].num_cuotas)+parseFloat(data[i].cuota4[0].ajustebs/data[i].tasa4)/parseFloat(data[i].cuota4[0].num_cuotas);
                } else if(!data[i].cuota4[0].pagada){
                    deuda4= parseFloat(data[i].cuota4[0].monto);
                } else {deuda4 = 0;}
            } else { cuota4 = null; }
			body.push({
                C1: i+1,
                C2: data[i].nucleo,
                C3: data[i].id,
                C4: data[i].apellido,
                C5: data[i].nombre,
                C6: data[i].plan_estudio,
                C7: cuota1,
                C8: deuda1,
                C9: cuota2,
                C10: deuda2,
                C11: cuota3,
                C12: deuda3,
                C13: cuota4,
                C14: deuda4,
                C15: data[i].correo,
                C16: data[i].tlf_celular

            });
        }
        
        body.push({
            C1: null,
            C2: null,
            C3: null,
            C4: null,
            C5: null,
            C6: null,
            C7: 'Pagados: '+contador1,
            C8: null,
            C9: 'Pagados: '+contador2,
            C10: null,
            C11: 'Pagados: '+contador3,
            C12: null,
            C13: 'Pagados: '+contador4,
            C14: null,
        });
        
        body.push({
            C1: null,
            C2: null,
            C3: null,
            C4: null,
            C5: null,
            C6: null,
            C7: 'Deudas: '+(data.length-contador1),
            C8: null,
            C9: 'Deudas: '+(data.length-contador2),
            C10: null,
            C11: 'Deudas: '+(data.length-contador3),
            C12: null,
            C13: 'Deudas: '+(data.length-contador4),
            C14: null,
        });
        

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16"],
            sheetHeader: ['Nº', 'Extensión', 'N° de Cédula', 'Apellidos', 'Nombres', 'Carrera', '1° Cuota', 'Deuda', '2° Cuota', 'Deuda' , '3° Cuota', 'Deuda', '4° Cuota', 'Deuda', 'Correo', 'Celular'],
            columnWidths: [2, 10, 5, 10, 10, 15, 5, 5, 5, 5, 5, 5, 5, 5, 10, 10],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    AsistenciaDOC(extension,periodo,turno,dia,data) {
        var option = {};
        option.fileName = extension.nombre+" Control de Asistencia Docente "+periodo.nombre+" Turno "+turno.nombre+" Día "+dia.nombre;
        var body = [];
        var horas = null;
        for (var i = 0; i < data.length; i++) {
            horas = '';
            for (var j = 0; j < data[i].horas.length; j++) {
                if(j>0){horas = horas +', ';}
                horas = horas +' '+ data[i].horas[j].nhora;
            }
			body.push({
                C1: i+1,
                C2: data[i].docente,
                C3: data[i].apellido+', '+data[i].nombre,
                C4: data[i].asignatura,
                C5: 'S'+data[i].sem+data[i].secc,
                C6: data[i].aula,
                C7: data[i].ndia,
                C8: horas,
                C9: null,
                C10: null
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10"],
            sheetHeader: ['Nº','N° Cédula','Docente', 'Asignatura', 'Sección', 'Aula', 'Día', 'Horario', 'Contenido Programático', 'Firma'],
            columnWidths: [2, 5, 20, 20, 5, 5, 5, 20, 20, 10],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    PosiGrad(nucleo,plan,data) {
        var option = {};
        option.fileName = "Posibles Graduandos de "+nucleo+" - "+plan;
        var body = [];
    
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].estudiante,
                C3: data[i].nombre+' '+data[i].apellido,
                C4: data[i].plan,
                C5: data[i].periodo,
                C6: data[i].periodofin,
                C7: data[i].cursado.uc,
                C8: data[i].tuc,
                C9: data[i].indicea,
                C10: data[i].indiceg,
                C11: data[i].recaudo+'%',
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11"],
            sheetHeader: ['Nº','Nº Cédula', 'Nombres y Apellidos', 'Plan de Estudio', 'P. Inicio', 'P. Culminación', 'UC Cursadas', 'UC Aprobadas',
                'IRA','Índice de Grado','Recaudos'],
            columnWidths: [2, 5, 15, 15, 6, 6, 6, 6, 6, 6, 6],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    ActaExt(acta,data) {
        var option = {};
        option.fileName = acta.nucleo+' - '+acta.programa+' - Graduandos Período '+acta.periodo;
        var body = [];
        var romano = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].detalle.tomo) {
                romano = Rom(data[i].detalle.tomo);
            } else {
                romano = null;
            }
			body.push({
                C1: i+1,
                C2: data[i].nombre+' '+data[i].apellido,
                C3: data[i].estudiante,
                C4: data[i].titulo,
                C5: Rom(data[i].detalle.libro),
                C6: data[i].detalle.acta,
                C7: this.convfech(data[i].fegreso),
                C8: data[i].detalle.indice,
                C9: data[i].detalle.indicea,
                C10: data[i].detalle.posicion,
                C11: data[i].detalle.posicion,
                C12: romano,
                C13: data[i].detalle.folio,
                C14: data[i].detalle.asignado,
                C15: data[i].detalle.resuelto,
                C16: this.convfech(data[i].detalle.resuelto_fecha),
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16"],
            sheetHeader: ['Nº','Nombres y Apellidos', 'Nº Cédula', 'Título',  'Libro', 'N° Acta de Grado', 'Fecha',
            'Índice de Grado', 'IRA', 'Posición de Grado', 'Posición por Extensión', 'Tomo', 'Folio', 'N° Asignado', 'N° Resuelto', 'Fecha del Resuelto'],
            columnWidths: [2, 15, 5, 15, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    RegAcad(acta,data) {
        var option = {};
        option.fileName = acta.nucleo+' - Regitro Académico del Período '+acta.periodo;
        var body = [];
    
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].nombre+' '+data[i].apellido,
                C3: data[i].estudiante,
                C4: data[i].titulo,
                C5: data[i].fegreso,
                C6: data[i].detalle.acta,
                C7: Rom(data[i].detalle.tomo),
                C8: data[i].detalle.folio,
                C9: data[i].detalle.asignado,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9"],
            sheetHeader: ['Nº','Nombres y Apellidos', 'Nº Cédula', 'Título', 'Fecha', 'N° Acta de Grado', 'N° Tomo', 'N° Folio', 'N° Asignado'],
            columnWidths: [2, 15, 5, 15, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Tasas(titulo,data) {
        var option = {};
        option.fileName = titulo;
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].fechad,
                C3: data[i].monto,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3"],
            sheetHeader: ['Nº','Fecha', 'Monto Bs.'],
            columnWidths: [2, 15, 15],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    convfech(fecha){
        if(fecha==null){
            return null;
        } else {
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
        }
    }
    
    CalcularIAG(data){
        /*
        var asigporuc = 0
        var cursadasuc = 0
        for (var i = 0; i < data.length; i++) {
            if(data[i].aprobada && data[i].tipo_id<5){
                cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);						
                asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
            } 
        }
        return parseFloat(asigporuc/cursadasuc).toFixed(2);
        */
        return data.length;

    }
    
    CalcularIA(data){
        /*
        var asigporuc = 0;
        var cursadasuc = 0;
        for (var i = 0; i < data.length; i++) {
            if(data[i].tipo_id<5){
                cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);
                asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
            }
        }
        return parseFloat(asigporuc/cursadasuc).toFixed(2);
        */
       return data.length;
    }

    OPSU1(year,extension,data) {
        var option = {};
        option.fileName = "OPSU - Alumnos "+extension.nucleo+" "+year;
        var body = [];
        let documento, sexo, turno, beca, activo;
        for (var i = 0; i < data.length; i++) {
            if(data[i].nac != 'P'){ documento = 'C';} else {documento = 'P';}
            if(data[i].sexo){ sexo = 0;} else {sexo = 1;}
            if(data[i].turno_id==0){turno=6;}
            if(data[i].turno_id==1){turno=3;}
            if(data[i].turno_id==2){turno=2;}
            if(data[i].turno_id==3){turno=4;}
            if(data[i].turno_id==4){turno=5;}
            if(data[i].beca){ beca = 2;} else {beca = 1;}
            if(data[i].activo){ activo = 1;} else {activo = 0;}
			body.push({
                C1: data[i].opsu,
                C2: data[i].programa,
                C3: 'No Aplica',
                C4: data[i].pais_nac,
                C5: 1,
                C6: documento,
                C7: data[i].estudiante,
                C8: data[i].apellido.toUpperCase(),
                C9: data[i].nombre.toUpperCase(),
                C10: sexo,
                C11: this.convfech(data[i].fecha_nac),
                C12: 5,
                C13: this.convfech(data[i].fingreso),
                C14: this.convfech(data[i].fecha_ultinsc),
                C15: turno,
                C16: beca,
                C17: activo,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17"],
            sheetHeader: ['1.codinstituto','2.programa','3.mencion','4.país','5.residencia','6.documento','7.cedula','8.apellidos',
                '9.nombres','10.sexo','11.fecha_nac','12.modalidad','13.fecha_ing','14.ultimo','15.turno','16.beca','17.condalumno'],
            columnWidths: [7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    OPSU2(year,extension,data) {
        var option = {};
        option.fileName = "OPSU - Egresados "+extension.nucleo+" "+year;
        var body = [];
        let documento, sexo, turno, beca, honor;
        for (var i = 0; i < data.length; i++) {
            if(data[i].nac != 'P'){ documento = 'C';} else {documento = 'P';}
            if(data[i].sexo){ sexo = 0;} else {sexo = 1;}
            if(data[i].turno_id==0){turno=6;}
            if(data[i].turno_id==1){turno=3;}
            if(data[i].turno_id==2){turno=2;}
            if(data[i].turno_id==3){turno=4;}
            if(data[i].turno_id==4){turno=5;}
            if(data[i].beca){ beca = 2;} else {beca = 1;}
            honor = 4;
            if(parseFloat(data[i].indiceg)>=18){ honor = 1;}
            if(parseFloat(data[i].indiceg)>=19.45){ honor = 3;}
			body.push({
                C1: data[i].opsu,
                C2: data[i].programa,
                C3: 'No Aplica',
                C4: data[i].pais_nac,
                C5: 1,
                C6: documento,
                C7:  data[i].estudiante,
                C8: data[i].apellido.toUpperCase(),
                C9: data[i].nombre.toUpperCase(),
                C10: sexo,
                C11: this.convfech(data[i].fecha_nac),
                C12: this.convfech(data[i].fegreso),
                C13: turno,
                C14: honor,
                C15: beca,
                C16: data[i].indiceg,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16"],
            sheetHeader: ['1.codinstituto','2.programa','3.mencion','4.país','5.residencia','6.documento','7.cedula','8.apellidos',
                '9.nombres','10.sexo','11.fecha_nac','12-fecha_egre','13.turno','14.honores','15.beca','16.promedio'],
            columnWidths: [7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    OPSU3(year,extension,data) {
        var option = {};
        option.fileName = "OPSU - Personal Académico "+extension.nucleo+" "+year;
        var body = [];
        let documento, sexo;
        for (var i = 0; i < data.length; i++) {
            if(data[i].nac != 'P'){ documento = 'C';} else {documento = 'P';}
            if(data[i].sexo){ sexo = 0;} else {sexo = 1;}
			body.push({
                C1: data[i].opsu,
                C2: data[i].pais_nac,
                C3: documento,
                C4: data[i].id,
                C5: data[i].apellido.toUpperCase(),
                C6: data[i].nombre.toUpperCase(),
                C7: sexo,
                C8: this.convfech(data[i].fecha_nac),
                C9: null,
                C10: null,
                C11: null,
                C12: null,
                C13: null,
                C14: null,
                C15: null,
                C16: null,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12","C13","C14","C15","C16","C17"],
            sheetHeader: ['1.codinstituto','2.país','3.documento','4.cedula','5.apellidos','6.nombres','7.sexo','8.fecha nac',
                '9.fecha ing','10.condición','11.categoría','12.cat inicial','13.dedicacion','14.título','15.profesión','16.adscripción'
            ],
            columnWidths: [7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

}


export default XLSjs;



//export default exports;